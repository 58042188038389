
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditLocationModal from "@/views/sbmx/modals/EditLocationModal.vue";
import { Location } from "@/store/modules/AuthModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "account-locations",
  components: { EditLocationModal },
  setup() {
    //const locations = ref([]);
    let l = null as Location | null;
    let empty = new Location();
    const selectedLocation = ref(l);
    const emptyLocation = ref(empty);
    const store = useStore();
    const loadingLocations = ref(0);

    const setActiveLocation = async function (location) {
      selectedLocation.value = null;
      await nextTick();
      selectedLocation.value = location;
    };

    const newLocation = async function () {
      selectedLocation.value = null;
      await nextTick();
      selectedLocation.value = new Location();
    };

    const refreshLocations = async function () {
      loadingLocations.value++;
      try {
        await store.dispatch(Actions.REFRESH_ACCOUNT_LOCATIONS);
      } catch (e) {
        console.log(e);
      } finally {
        loadingLocations.value--;
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Locations", ["Account"]);
      refreshLocations();
    });
    return {
      selectedLocation,
      setActiveLocation,
      refreshLocations,
      newLocation,
      emptyLocation,
      loadingLocations,
      Actions,
    };
  },
});
